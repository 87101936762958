<template>
  <v-container fluid :class="['component', {'desktop':$vuetify.breakpoint.width>=960}]" v-if="videos.length>0">
    <v-row>
        <v-col cols="1" sm="2"/>
        <v-col>
            <h1 class="white--text"><Str index="program.videos.most_liked.title"/></h1>
        </v-col>
        <v-col cols="3"/>
    </v-row>
    <v-row>
        <v-col cols="12" lg="1"/>
        <v-col cols="12" lg="10" style="text-align: center;">
            
            <div class="card-positioner" v-if="$vuetify.breakpoint.width>=960">
                <div class="card-container">
                    <template v-for="(video, index) in videos">
                        <Card  v-if="index<3" :language="language" :debug="debug" :index="index" :color="index==0 ? 'white' : 'black'" :VideoController="VideoController" class="MostLikedVideoCard" :key="`rank-${index}`" :video="video"/>
                    </template>                    
                </div>
            </div>
                    
            <template v-else><!-- Display recomended videos in carousel for viewport width smaller than 767px -->
                <v-carousel v-model="carousel_model" 
                    class="custom"
                    :continuous="false"
                    :cycle="true"
                    :show-arrows-on-hover="false"
                    :show-arrows="true"
                    height="auto"
                    hide-delimiters>
                    <v-carousel-item v-for="(video, index) in videos" :key="'slide-'+index" style="height: auto;"
                        class="fill-height justify-center align-center">
                        <Card  :language="language" :debug="debug" :index="index" :color="index==0 ? 'white' : 'black'" :VideoController="VideoController" class="CarouselMostLikedVideoCard" :key="`rank-${index}`" :video="video"/>
                    </v-carousel-item>
                </v-carousel>
            </template>
        </v-col>
        <v-col cols="12" lg="1"/>
    </v-row>
  </v-container>
</template>

<script>
import Card from './lib/mostLikedCard.vue'
export default {
    props: {
        language: {
            type: String,
            required: true
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        VideoController: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            carousel_model: 0
        }
    },
    components:{
        Card
    },
    computed: {
        videos(){
            return this.VideoController.mostLiked.slice(0, 3)
        }
    }
}
</script>

<style lang="scss" scoped>
.component{
    background-color: $gray;
    
    .desktop {
        margin-bottom: 150px;
    }
}
.card-positioner{
    z-index: 7;
    position: relative;
    width: 100%;
    height: 100px;
}
.card-container{
    position: absolute;
    top: 25px;
    left: 0px;
    width: 100%;
    text-align: center;
}
.MostLikedVideoCard{
    display: inline-block;
    margin: -15px;
    width: 36%;
    position: relative;
    top: -15px; 
}

.MostLikedVideoCard:nth-child(1){
    color: white;
    margin-right: -60px;
    top: -20px;
    left: 11px;
    z-index: 5;
}

.MostLikedVideoCard:nth-child(2){
    top: -60px;
    z-index: 3;
}

.MostLikedVideoCard:nth-child(3){
    z-index: 1;
    top: -25px;
}

@media only screen and (max-width: 1600px) {
    .MostLikedVideoCard:nth-child(1){
        left: initial;
        top: -40px
    }
}

@media only screen and (max-width: 994px) {
    .MostLikedVideoCard{
        width: 37%;
    }
}

@media only screen and (max-width: 800px) {
    .MostLikedVideoCard{
        width: 100%;
    }
}

::v-deep .v-window {
    overflow: initial;   
}

::v-deep .v-carousel {
    overflow-y: initial;
}

::v-deep .v-window__prev {
    left: initial !important;
    right: 36px !important;
    top: -88px;
}
::v-deep .v-window__next {
    top: -88px;
}
</style>