<template>
    <v-container fluid class="component-videos">        
        <v-row class="px-4">
            <v-col cols="12" sm="2"></v-col>
            <v-col :class="[{'pl-8 pt-8':$vuetify.breakpoint.smAndUp}, {'pt-11':$vuetify.breakpoint.xsOnly}]">
                <h1><Str index="program.videos.title"/></h1>
                <p class="font-weight-bold"><Str index="program.videos.description"/></p>
            </v-col>
            <v-col cols="12" sm="2"></v-col>
        </v-row>
        <v-row v-if="state.view=='selection' && moreThanThree">
            <v-col class='pb-11'>
                <MostLiked :language="language" :VideoController="controller" :debug="debug"/>
            </v-col>
        </v-row>
        <v-row v-if="$route.name=='Video'" class="crumbs">
            <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
            <v-col cols="12" sm="10" align-self="center" style="text-align: right;">
                <div>
                    <router-link :to="{name:'Home'}"><Str index="header.home"/></router-link>
                    <template v-for="(route, index) in $route.matched">
                        <span  v-if="controller.selected"  :key="`route-index-${index}`">
                            >
                            <router-link :to="{name:route.name}"> {{ $route.matched.length>1 && index==$route.matched.length-1 ? controller.selected.title[language] : $store.getters.LabelController.getLabel('program.videos.title') }}</router-link>
                        </span>
                    
                    </template>
                </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
        </v-row>
        <v-row :style="{
               'margin-top' : $vuetify.breakpoint.width>=1800 && state.view==='selection' ? '160px'
               : $vuetify.breakpoint.width>=1600 && state.view==='selection' ? '130px'
               : $vuetify.breakpoint.width>=1200 && state.view==='selection' ? '112px'
               : $vuetify.breakpoint.width>=1000 && state.view==='selection' ? '100px'
               : $vuetify.breakpoint.width>=960 && state.view==='selection' ? '90px' : 'initial'
               }">
            <v-col>
                <template v-if="state.view=='selection'">                    
                    <VideoList :language="language" :debug="debug" :controller="controller"/>
                </template>
                <PlayVideo v-if="state.view=='player' && controller.selected" :language="language" :debug="debug" :controller="controller" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VideoList from '@/components/Videos/VideoList'
import MostLiked from '@/components/Videos/MostLiked'
import PlayVideo from '@/components/Videos/PlayVideo'
export default {
    props: {
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    components:{
        VideoList,
        MostLiked,
        PlayVideo
    },
    methods: {
        randomizeLikeCount(){
            let controller = this.controller
            for (let index = 0; index < 50; index++) {
                controller.likeRandomVideos()                
            }
        }
    },
    computed: {
        controller(){
            return this.$store.getters.VideoController
        },
        state(){
            return {
                view: this.$route.name=='Educational Videos' ? 'selection' : 'player'
            }
        },
        debug(){
            return this.$store.getters.debug
        },
        moreThanThree(){
            let videos = this.controller.videos
            let counter = 0
            for (let index = 0; index < videos.length; index++) {
                const video = videos[index];
                if(video.likes>0){
                    counter++
                }
            }
            return counter>=3
        }
    }
}
</script>

<style lang="scss" scoped>
.component-videos{
    padding: 0px;
}

.crumbs{
    width: 100%;
    height: 60px;
    background-color: $lightgray;
}

h1{
    color: $pink;
    font-weight: bold;
    font-size: 3em;
    line-height: normal;
}
@media only screen and (max-width: 768px) {
.crumbs {
    font-size: 14px;
} 
}

@media only screen and (max-width: 600px) {
.crumbs {
    font-size: 12px;
}   
}
</style>