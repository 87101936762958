<template>
  <v-container style="max-width:1024px;">
    <v-row>
            <div v-if="ui.whatsNext" class="whatsNext">
              <v-card width="100%">
                <v-card-title class="custom-green">
                  <Str index="playVideo.whatsNext.title"/>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li>
                      <Str index="playVideo.whatsNext.bullet.1"/>
                      <v-btn text x-small @click="controller.setLiked({video})">
                            <v-icon small :class="[{liked:video.status.liked}]">
                                mdi-thumb-up-outline
                            </v-icon>
                            <Str index="controller.video.button.like"/>
                        </v-btn>                      
                    </li>
                    <li>
                      <Str index="playVideo.whatsNext.bullet.2"/>
                        <v-btn text x-small @click="controller.downloadSummary({video})">
                            <v-icon>
                                mdi-file-download-outline
                            </v-icon>
                            <Str index="controller.video.button.summary"/>
                        </v-btn>
                    </li>
                    <li v-if="!controller.allWatched">
                      <Str index="playVideo.whatsNext.bullet.3"/>
                    </li>
                    <li>
                      <router-link style="text-decoration: none; color: inherit;" :to="{name:'Evaluation'}"><Str index="playVideo.whatsNext.bullet.4"/></router-link>
                    </li>
                  </ul>
                  <sub>
                    <Str index="playVideo.whatsNext.note"/>
                  </sub>
                </v-card-text>
                <v-card-actions class="pa-0 custom-gray no-gutters">
                  <v-btn style="width:60%" tile x-large dark color="pink" @click="$router.push({name: 'Educational Videos'})" depressed>
                    <Str index="controller.video.navigation.all_videos"/>
                  </v-btn>                   
                  <v-btn style="width:40%" tile v-if="controller.getNextVideo()" x-large dark color="green" @click="controller.gotoVideo(controller.getNextVideo());ui.whatsNext = false" depressed class="ml-0">
                    <Str index="controller.video.navigation.next_video"/>
                  </v-btn>
                  <v-btn style="width:40%" tile v-else x-large dark color="green" @click="$router.push({name: 'Dashboard'})" depressed class="ml-0">
                    <Str index="header.dashboard"/>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
      <v-col>
        <v-card style="border-bottom: 1px solid black;" flat :tile="true">
          <div class="video">
            <VideoPlayer 
              v-if="signedUrl" 
              :source="signedUrl"
              class="videoPlayer"
              :class="[{finishedVideo:finishedVideo}]"
              :options="{video:{controls: true || debug, autoplay: true, playsinline: true}}"
              @playing="playing"
              @ended="ended"
              @seeking="seeking"
              @paused="paused"
            />

            <!-- <div v-if="!ui.whatsNext" style="display: inline-flex; align-items: center;">            
              <v-btn icon>
                  <v-icon x-large :color="video.status.finished ? 'green' : 'gray'">
                      {{video.status.finished ? 'mdi-checkbox-marked' : 'mdi-play-circle'}}
                  </v-icon>
              </v-btn>
            </div> -->
          </div>
          <!-- <v-progress-linear :value="video.status.played[language]"></v-progress-linear> -->
          <div v-if="debug">
            <v-btn text x-small @click="controller.setStarted({video, state:!video.status.started ,language})">Toggle Started</v-btn>
            <v-btn text x-small @click="controller.setFinished({video, state:!video.status.finished ,language})">Toggle Finished</v-btn>
          </div>
          <div class="video-actions">
            <v-container fluid>
                <v-row dense>
                    <v-col cols="4" sm="2">
                        <v-btn text small @click="controller.setLiked({video})">
                            <v-icon :class="[{liked:video.status.liked}]">
                                mdi-thumb-up-outline
                            </v-icon>
                            <Str index="controller.video.button.like"/>
                        </v-btn>
                    </v-col>
                    <v-col cols="4" sm="2">
                        <v-btn text small @click="controller.downloadSummary({video, state:true})">
                            <v-icon>
                                mdi-file-download-outline
                            </v-icon>
                            <Str index="controller.video.button.summary"/>
                        </v-btn>
                    </v-col>
                    <v-col cols="4" sm="2">
                        <v-btn text small>
                            {{video.duration[language]}} min.
                        </v-btn>
                    </v-col>
                    <v-col/>
                </v-row>
            </v-container>
          </div>
          <div class="video-details">
            <div class="video-title" v-html="video.title[language]"/>
            <div class="video-description" v-html="video.description[language]"></div>
          </div>
        </v-card>
      </v-col>
    </v-row>
                        
    <v-row style="text-align: center;">
      <v-col cols="12">
        <router-link :to="{name:'Educational Videos'}"><Str index="controller.video.navigation.back_to_all_videos"/></router-link>
      </v-col>        
      <v-col cols="6">
        <v-btn class="pa-0" text :disabled="controller.getPreviousVideo()===undefined" @click="controller.gotoVideo(controller.getPreviousVideo())">
          &lt;&lt; <Str index="controller.video.navigation.previous_video"/>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn class="pa-0" text :disabled="controller.getNextVideo()===undefined" @click="controller.gotoVideo(controller.getNextVideo())">
          <Str index="controller.video.navigation.next_video"/> &gt;&gt;
        </v-btn>
      </v-col>
    </v-row>
    
      <v-row v-if="$vuetify.breakpoint.width>=600">
      <v-col>
        <VideoCard v-if="controller.getPreviousVideo()" :debug="debug" :videoIndex="0" :video="controller.getPreviousVideo()" :controller="controller" :language="language"/>
      </v-col>
      <v-col>
        <VideoCard v-if="controller.getNextVideo()" :debug="debug" :videoIndex="1" :video="controller.getNextVideo()" :controller="controller" :language="language"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoPlayer from './lib/videoPlayer.vue'
import VideoCard from './lib/videoCard'
export default {
    created(){
      this.init()
    },
    destroyed(){
      clearTimeout(this.timeout)
      clearTimeout(this.renewTimeout)
    },
    components: {
      VideoPlayer,
      VideoCard
    },
    props: {
      language: {
        type: String,
        required: false,
        default: 'en-ca'
      },
      controller: {
          type: Object,
          required: true
      },
      debug: {
          type: Boolean,
          required: true
      }
    },
    data: function(){
      return {
        ui: {
          whatsNext: false
        },
        signedUrl: undefined,
        expires: undefined,
        finishedVideo: false,
        timeout: null,
        renewTimeout: null,
        player: {
            state: 'idle'
        }
      }
    },
    methods: {
        paused(packet){
          this.player.state = 'paused'
        },
      async init(){
        this.signedUrl = undefined
        this.expires = undefined
        this.finishedVideo = false
        this.ui.whatsNext = false
        let video = this.video
        let language = this.language
        await this.getSignedUrl(video)
        
        await this.$store.getters.UserController.hs.program.update({video, percentage: video.status.played[language]})

        await this.sendRequest('put','/api/log',{action:'playVideo',parameters:{video:video.id,language}})
      },
      async getSignedUrl(video){
        let language = this.language
        let response = await this.sendRequest('post','/api/assets',{fileName:video.file[language]})
        if(response.status==200){
          this.signedUrl = response.data.url
          let t = new Date()
          t.setSeconds(t.getSeconds()+(response.data.expires))
          this.expires = t
          this.$forceUpdate()
          this.renewUrl()
        }
      },
      async renewUrl(){
        clearTimeout(this.renewTimeout)
        let self = this
        let t = new Date()
        let test = {
          trigger: t>=this.expires,
          now: t,
          expires: this.expires,
          timeleft: new Date(this.expires-t)
        }        
        if(test.trigger){
            if (this.player.state!=='paused'){
                await this.init()
            }
        }
        
        //console.log(test)
        
        this.renewTimeout = setTimeout(() => {
          self.renewUrl()
        }, 2000);
      },
      async playing(packet){
        this.finishedVideo = packet.currentTime>=packet.duration
        this.player.state = 'playing'

        let language = this.language
        let video = this.video
        let percentage = Math.round((packet.currentTime/packet.duration)*100)
        await this.controller.setStarted({video, state:true, language})
        let self = this
        // clearTimeout(self.timeout)
        // self.timeout = setTimeout(() => {
          self.controller.setPlayed({video, language, percentage})
        // }, 5000);
      },
      ended(packet){
        let video = this.video
        this.finishedVideo = true
        this.ui.whatsNext = true
        let self = this
        clearTimeout(self.timeout)
        self.controller.setFinished({video, state:true})
        // self.timeout = setTimeout(() => {
        //   self.controller.setFinished({video, state:true})
        // }, 1000);
        
      },
      seeking(){
        this.finishedVideo = false
      }
    },
    computed: {
      video(){
        return this.controller.selected
      },
      finished(){
        return this.video.status.finished
      },
      video_id(){
        return this.video ? this.video.id : undefined
      }
    },
    watch: {
      video_id(){
        this.init()
      },
      language(){
        this.init()
      }
    }
}
</script>

<style lang="scss" scoped>
.video{
  // border: 1px solid $gray;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.video-actions{
  height: 40px;
}
.video-details{
  padding: 15px;
}
.video-title{
  color: $pink;
  font-weight: bold;
  font-size: 2em;
  line-height: 1.5em;
  margin: 15px 0px 15px 0px;
}
.video-description{
  font-size: 0.8em;
}

.whatsNext{
  display: inline-flex; 
  align-items: center;
  width: 100%;
  max-width:1024px;
  position: absolute;
  z-index: 10;
}

@media only screen and (max-width: 600px) {
    .whatsNext {
        
        .v-btn {
            font-size: 12px;
            padding-left: 0;
            padding-right: 0;
        }
    }   
}

.custom-gray{
  background-color: $gray;
}
.custom-green{
  color: $green;
  font-weight: bold;
}

.liked{
    color: $blue !important;
    caret-color: $blue !important;
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: $green; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  font-size: 2em;
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  position: relative;
  top: 0.3em;
  left: 0.3em;
}


.videoPlayer{
  //position: absolute;
  //top: 0px;
  //left: 0px;
}

.finishedVideo{
  opacity: 0.2;
}
</style>