<template>
   <div>
    <v-container dense v-if="response">
      <v-row justify="center">
        <v-spacer/>
        <v-col cols="3" v-if="$store.getters.UserController.authorized(['admin','staff','tester'])">
          <v-select      
            v-model="filters.groups"
            :items="_filters.groups"
            chips
            deletable-chips
            clearable
            multiple
            prepend-icon="mdi-filter-variant"
            color="success"
            item-color="success"
          />
        </v-col>
        <v-spacer/>        
      </v-row>
      <v-row>
        <v-col class="pa-0 text-center">
          <Box class="StatsBox" :Data="{value: processed.visits, text: 'Total Site Visits'}"/>
          <Box class="StatsBox" :Data="{value: processed.users.length, text: 'Registered Users'}"/>
          <Box class="StatsBox" :Data="{value: processed.videos.watched_5.length, text: 'Users with >=5 video views'}"/>
          <Box class="StatsBox" :Data="{value: processed.videos.watched_12.length, text: 'Users with >=12 video views'}"/>
          <Box class="StatsBox" :Data="{value: processed.certificates.length, text: 'Certificates Generated'}"/>
          <Box class="StatsBox" :Data="{value: sumCredits(processed.credits), text: 'Credits Earned'}"/>
          <Box class="StatsBox" :Data="{value: processed.answers.initial.length, text: 'Pledges signed'}"/>
          <Box class="StatsBox" :Data="{value: processed.answers.followUp.length, text: 'Pledge Follow ups completed'}"/>
          <Box class="StatsBox" :Data="{value: response.invites[0].invites, text: 'Invites'}"/>          
        </v-col>
      </v-row>

      <v-row>
        <v-col>        
          <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header ripple>User Engagement</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <v-data-table
                        id="progress_report"
                        :headers="table.headers"
                        :items="table.records"
                        fixed-header
                        height="600"
                        hide-default-footer
                        disable-pagination
                      />

                      <div style="text-align: right;">
                        <v-btn small v-if="allow(['admin','staff','tester'])" @click="ExportToExcel('progress_report','xls')" color="green" class="white--text">Download</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header ripple>Media Stats</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                        <v-row justify="end" no-gutters>
                            <v-btn @click="reloadMediaStats()" :loading="loading" small outlined class="ma-1 bgcolor-brand-yellow">Refresh <v-icon>mdi-refresh </v-icon></v-btn>
                        </v-row>
                        <v-data-table
                          id="media-stats-report"
                          :headers="mediaStatsTable.headers"
                          :items="mediaStatsTable.records"
                          hide-default-footer
                          disable-pagination
                        />
                        <div style="text-align: right;">
                          <v-btn small v-if="allow(['admin','staff','tester'])" @click="ExportToExcel('media-stats-report','xls')" color="green" class="white--text">Download</v-btn>
                        </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>          
          </v-expansion-panels>
        </v-col>
      </v-row>

      <template v-if="allow(['admin','staff','tester'])">
        <hr style="margin: 50px;">
  
        <v-row>
          <v-col>
            <v-tabs v-model="tab">
              <v-tab>
                Pledge
              </v-tab>
              <v-tab>
                Follow-Up
              </v-tab>
              <v-tab>
                Evaluation
              </v-tab>
            </v-tabs>
  
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card>
                  <v-card-title>
                      <h2 align="center">Pledge: <sub>n</sub>{{ processed.answers.initial.length }}</h2>
                  </v-card-title>
                  <v-card-text  class="pa-0">
                    <Table style="display: inline-block; margin: 15px;" :ExportToExcel="ExportToExcel" :language="language" :debug="debug" :questionSet="_pledge" :submissions="processed.answers.initial" :allowDownload="allow(['admin','staff','tester'])" :export_id="'pledge'" :exportButton="'pledge'"/>
                    <!-- <List :questionSet="_pledge" :answers="processed.answers.initial" :config="config.pledges.initial" :language="language"/> -->
                  </v-card-text>
                </v-card>              
              </v-tab-item>
  
              <v-tab-item>
                <v-card>
                  <v-card-title>
                      <h2 align="center">FollowUp: <sub>n</sub>{{ processed.answers.followUp.length }}</h2>
                  </v-card-title>
                  <v-card-text  class="pa-0">
                    <Table style="display: inline-block; margin: 15px;" :ExportToExcel="ExportToExcel" :language="language" :debug="debug" :questionSet="_followUp" :submissions="processed.answers.followUp" :allowDownload="allow(['admin','staff','tester'])" :export_id="'followUp'" :exportButton="'followUp'"/>
                    <!-- <List :questionSet="_followUp" :answers="processed.answers.followUp" :config="config.pledges.followUp" :language="language"/> -->
                  </v-card-text>
                </v-card>
              </v-tab-item>
  
              <v-tab-item>
                <v-card>
                  <v-card-title>
                      <h2 align="center">Evaluation: <sub>n</sub>{{ processed.answers.evaluation.length }}</h2>
                  </v-card-title>
                  <v-card-text  class="pa-0">
                    <Table style="display: inline-block; margin: 15px;" :ExportToExcel="ExportToExcel" :language="language" :debug="debug" :questionSet="_evaluation" :submissions="processed.answers.evaluation" :allowDownload="allow(['admin','staff','tester'])" :export_id="'evaluations'" :exportButton="'evaluations'"/>
                    <!-- <List :questionSet="_evaluation" :answers="processed.answers.evaluation" :config="config.evaluation" :language="language"/> -->
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>            
          </v-col>
        </v-row>    
      </template>
    </v-container>

    <v-card v-else width="100" style="margin: 100px auto;">
      <v-card-text style="text-align: center;">
        <v-icon x-large>mdi-spin mdi-loading</v-icon>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import Box from '@/components/UsageReport/Box'
import Table from '@/components/UsageReport/Table'
import List from '@/components/UsageReport/List'

export default {
  name: "UsageReport",
  components: {
    Box,
    Table,
    List
  },
  created(){
    this.init()
  },
  data(){
    return {
      config: {
        pledges: {
          initial: {id: 1},
          followUp: {id: 2}
        },
        evaluation: {
          id: 3
        }
      },
      response: null,
      filters: {
        groups: ['user']
      },
      tab: 0,
      loading: false,
      table_engagement_records: null
    }
  },
  methods: {
    async init(){
      this.response = null
      try {
        let response = await this.sendRequest('get','/api/report')
        if(response.status==200){
          this.response = response.data
        }
      } catch (error) {
        
      }
    },
    isUser(user_id){
        let users = this.getUsers("isUser")
        let search = users.filter((user)=>{return user.user_id==user_id})
        return search.length>0
    },
    getVists(){
      console.log('getVists',this.response.visits)
      return this.response.visits ? this.response.visits[0].visits : '--'
    },
    getUsers(origin="default"){
        if (this.table_engagement_records===null){
            return []
        }

        let self = this
        if (this.table_engagement_records!==null){
            return this.table_engagement_records
        }
        
        let pushedUsenameSet = []
        function getUsersWithoutGroup(){
            //let allUsers = self.response.cognitoData.users.filter((user)=>{return user.Enabled===true && user.UserStatus=='CONFIRMED'})
            let allUsers = self.response.cognitoData.users.filter(user=>1==1)
            let users = []
            for(let i=0; i<allUsers.length; i++){
                let user = allUsers[i]
                let found = false
                for(let groupName in self.response.cognitoData.groupUsers){
                    let groupUsers = self.response.cognitoData.groupUsers[groupName]
                    let search = groupUsers.filter((item)=>{return item.Username==user.Username})
                    if(search.length>0){
                        found = true
                        break
                    }
                }
                if(!found){
                    if (pushedUsenameSet.includes(user.Username)===false){
                        users.push(user)
                        pushedUsenameSet.push(user.Username)
                    }
                }
            }
      
            return users
        }
            
        if(this.response){
            if(this.filters.groups.length>0){
                let users = this.filters.groups.includes('user') ? getUsersWithoutGroup() : []

                for(let i=0; i<this.filters.groups.length; i++){
                    let groupName = this.filters.groups[i]
                    if(groupName!='user'){
                        for(let ii=0; ii<this.response.cognitoData.groupUsers[groupName].length; ii++){
                            let user = this.response.cognitoData.groupUsers[groupName][ii]
                            if (pushedUsenameSet.includes(user.Username)===false){
                                users.push(user)
                                pushedUsenameSet.push(user.Username)
                            }
                            // let found = self.response.cognitoData.users.filter((item)=>{return item.Username==user.Username})
                            // console.log('finding group',{
                            //   user, found, groupName, users
                            // })
                            // if(found.length>0){
                            //   users.push(user)
                            // }
                        }
                    }
                }

                //return users.filter((user)=>{return user.Enabled===true && user.UserStatus=='CONFIRMED'})
                return users.filter(user=>1==1)
            }
        }
        return []
    },
    getVideoStats(){
      function getViews(object, {type, number}){
        let output = []
        for(let user_id in object){
          let record = object[user_id]
          let test = record[type]>=number
          if(test===true){
            // console.log('test',{record, type, number, test})
            output.push({user_id, started: record.started, finished: record.finished})
          }
        }
        
        return output
      }
      if(this.response){
        let logs = this.response.videoLogs
        let stats = {
          byUser: {}
        }
        for(let i=0; i<logs.length; i++){
          let record = logs[i]
          if(this.isUser(record.user_id)){
            if(!stats.byUser[record.user_id]){
              stats.byUser[record.user_id] = {
                started: 0,
                finished: 0
              }
            }
            if(record.started==1){stats.byUser[record.user_id].started++}
            if(record.finished==1){stats.byUser[record.user_id].finished++}
          }
        }


        return {
          stats,
          watched_5: getViews(stats.byUser,{type: 'finished', number: 5}),
          watched_12: getViews(stats.byUser,{type: 'finished', number: 12}),
          logs: this.response.videoLogs
        }
      }
      return {}
    },
    getVideoInfo(){
      let output = {}
      if(this.response){
        for(let i=0; i<this.response.videoInfo.length; i++){
          let record = this.response.videoInfo[i]
          output[record.id] = record
        }
      }

      return output
    },
    getCredits(){
      let output = {}

      if(this.response){
        let logs = this.response.videoLogs
        let videoMap = this.getVideoInfo()
        for(let i=0; i<logs.length; i++){
          let record = logs[i]
          let video = videoMap[record.video_id]
          if(this.isUser(record.user_id)){
            if(!output[record.user_id]){
              output[record.user_id] = 0
            }
            if (record.started==1 && record.finished==1){
                output[record.user_id] += video.credits
            }
          }
        }
      }
      return output
    },
    sumCredits(object){
      let credits = 0
      for(let user_id in object){
        credits += object[user_id]
      }
      return credits
    },
    getCertificates(){
      let output = []
      for(let i=0; i<this.response.certificates.length; i++){
        let certificate = this.response.certificates[i]
        if(this.isUser(certificate.user_id)){
          output.push(certificate)
        }
      }

      return output
    },
    getAnswers(){
      let config = this.config
      let output = {
        initial: [],
        followUp: [],
        evaluation: []
      }
      let answers = this.response.pledges
      for(let i=0; i<answers.length; i++){
        let record = answers[i]
        if(record.questionSetId == config.pledges.initial.id && this.isUser(record.user_id)){
          output.initial.push(record)
        }
        if(record.questionSetId == config.pledges.followUp.id && this.isUser(record.user_id)){
          output.followUp.push(record)
        }
        if(record.questionSetId == config.evaluation.id && this.isUser(record.user_id)){
          output.evaluation.push(record)
        }
      }

      return output
    },
    getLogins(){
      let output = {}
      let logins = this.response.logins
      for(let i=0; i<logins.length; i++){
        let record = logins[i]
        output[record.user_id] = record
      }

      return output
    },
    rawAnswers(questionSet, submissions){
      let language = this.language
      let output = {}

      for(let s=0; s<questionSet.sections.length; s++){
        let section = questionSet.sections[s]
        output[section.id] = {
          definition: section,
          submissions: []
        }

        for(let i=0; i<submissions.length; i++){
          let submission = submissions[i]
          output[section.id].submissions.push({
            record_id: submission.id,
            user_id: submission.user_id,
            created: submission.created,
            sectionAnswers: submission.answers[section.id]
          })
        }
      }

      return output
    },
    ExportToExcel(id, type, fn, dl) {
       var elt = document.getElementById(id);
       var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
       return dl ?
         XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
         XLSX.writeFile(wb, fn || (`${id}.` + (type || 'xlsx')));
    },
    allow(groups){
        return this.$store.getters.UserController && this.$store.getters.UserController.authorized(groups)
    },
    async reloadMediaStats(){
        this.loading = true
        this.loading = await this.$store.getters.VideoController.init()==='init-videos-complete' ? false :  true
    }
  },
  computed: {
    debug(){
        return this.$store.getters.debug
    },
    processed(){
        return {
            visits: this.getVists(),
            users: this.getUsers("processed"),
            videos: this.getVideoStats(),
            videoInfo: this.getVideoInfo(),
            credits: this.getCredits(),
            certificates: this.getCertificates(),
            answers: this.getAnswers(),
            logins: this.getLogins()
        }
    },
    table(){
        let self = this
        let engagementRecords
        if (self.response.userEngagement[0].name===undefined){
            engagementRecords = self.response.userEngagement.map((item)=>{
                let user = self.response.cognitoData.users.filter(cognitoItem => cognitoItem.Username==item.user_id)[0]
                if (user){
                    let given_name_index = user.Attributes.findIndex((attrItem)=>{return attrItem.Name=='given_name'})
                    let family_name_index = user.Attributes.findIndex((attrItem)=>{return attrItem.Name=='family_name'})
                    item['name'] = ''
                    if (given_name_index>=0 ){
                        item['name'] += `${user.Attributes[given_name_index].Value}`
                    }
                    if (family_name_index>=0){
                        item['name'] += ` ${user.Attributes[family_name_index].Value}`         
                    }

                    item.lastLogin = item.lastLogin!==null ? new Intl.DateTimeFormat("en-GB", {year: "numeric", month: "2-digit", day: "2-digit"}).format(new Date(item.lastLogin)) : 'N/A'

                    item['user_state'] = user.UserStatus
                    
                    item['create_date'] = new Intl.DateTimeFormat("en-GB", {year: "numeric", month: "2-digit", day: "2-digit"}).format(new Date(user.UserCreateDate))
                    
                    /* supress postal code from displayed
                    let postal_code_index = user.Attributes.findIndex((attrItem)=>{return attrItem.Name=='custom:postal_code'})
                    item['postal_code'] = postal_code_index>=0 ? `${user.Attributes[postal_code_index].Value}` : ''
                    */

                    item['groups'] = 'user'
                    for(let groupName in self.response.cognitoData.groupUsers){
                        let groupUsers = self.response.cognitoData.groupUsers[groupName]
                        let search = groupUsers.findIndex((groupItem)=>{return groupItem.Username==user.Username})               
                        if (search>=0){
                            if (item['groups'] == 'user') {
                                item['groups'] = groupName
                            }
                            else{
                                item['groups'] += `##${groupName}`
                            }
                        }
                    }                
                }
                return item
            })
        }
        else{
            engagementRecords = self.response.userEngagement
        }
        
        this.table_engagement_records = engagementRecords.filter((user)=>{
            for(let i=0; i<this.filters.groups.length; i++){
                let groupName = this.filters.groups[i]
                if (user.groups!==undefined && user.groups.includes(groupName)){
                    return user
                }
            }
        })
        
        return {
            headers: [
              {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
              },
              { text: 'Status', value: 'user_state' },
              { text: 'Registration date', value: 'create_date', width: '110' },
              { text: 'Email', value: 'email' },
              { text: 'User Type', value: 'groups', width: '130' },
              //{ text: 'Postal Code', value: 'postal_code' },
              { text: 'Last Visit', value: 'lastLogin' },
              { text: 'Videos Started', value: 'videosStarted', width: '100' },
              { text: 'Videos Finished', value: 'videosFinished', width: '100' },
              { text: 'Certificates generated by user', value: 'certificates', width: '115' },
              { text: 'Credits downloaded in certificate', value: 'credits', width: '90' },
              { text: 'Initial Pledge', value: 'initialPledge', width: '100' },
              { text: 'Follow-Up Pledge', value: 'followUpPledge', width: '100' },
              { text: 'CmpgID', value: 'campaignID' },
            ],
            records: this.table_engagement_records
        }
    },
    language(){
      return this.$store.getters.LanguageController.language
    },
    _evaluation(){
      return this.response.questionSets[this.config.evaluation.id][0]
    },
    _pledge(){
      return this.response.questionSets[this.config.pledges.initial.id][0]
    },
    _followUp(){
      return this.response.questionSets[this.config.pledges.followUp.id][0]
    },
    _filters(){
        let groups = [
          { text: "User", value: 'user' }
        ]
        
        if(this.response){
          for(let i=0; i<this.response.cognitoData.groups.length; i++){
            let record = this.response.cognitoData.groups[i]
            groups.push({
              text: record.GroupName,
              value: record.GroupName
            })
          }
        }
        return {
          groups
        }
    },
    mediaStatsTable(){
        return {
            headers: [
              {
                text: '#Video-ID',
                align: 'start',
                sortable: true,
                value: 'id',
              },
              { text: 'Title', value: 'title.en-ca' },
              { text: 'Views EN-CA', value: 'en-ca_views' },
              { text: 'Likes EN-CA', value: 'en-ca_likes' },
              { text: 'Views FR-CA', value: 'fr-ca_views' },              
              { text: 'Likes FR-CA', value: 'fr-ca_likes' }
            ],
            records: this.$store.getters.VideoController.videos
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.StatsBox{
  margin: 15px;
  width: 260px;
  display: inline-block;
  background-color: #02C5F3;
}

.td-data{
 text-align: center;
}

.td-counter{
  text-decoration: underline;
}

.td-choice{
  font-weight: bold;
}

.td-answer{
  font-style: italic;
}
::v-deep .theme--light.v-icon {
    color: $green !important;
}

::v-deep #progress_report,
::v-deep #media-stats-report
{
    tr th {
        background-color: $pink;
        color: #ffffff;
        
        .v-icon {
            color: $yellow !important;
        }
    }
}

</style>