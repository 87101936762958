<template>
  <v-card>
    <div class="video-image" :style="{'background-image':`URL(${video.thumbnail[language]})`}">
        <div style="display: inline-flex; align-items: center;">            
            <v-btn icon style="transform: translateY(-50%)" @click="gotoVideo(video)">
                <img style="width: 35px;" :src="video.status.finished ? icons.finished : icons.play"/>
                <!-- <v-icon x-large :color="video.status.finished ? 'green' : 'gray'">
                    {{video.status.finished ? 'mdi-checkbox-marked' : 'mdi-play-circle'}}
                </v-icon> -->
            </v-btn>
        </div>
    </div>
    <!-- <v-progress-linear :value="video.status.played[language]"></v-progress-linear> -->
    <div class="video-decoration" :style="{'background-image':`URL('${decoration[color]}')`}">
        <v-container fluid dense>
            <v-row>
                <v-col>
                    <span class="debug" v-if="debug">Likes: {{ video.likes }}</span>&nbsp;<span class="debug" v-if="debug">Views: {{video[`${language}_views`]}}</span>
                </v-col>
            </v-row>
        </v-container>
    </div>

    <div v-if="debug">
        <v-btn class="debug" text x-small @click="controller.setStarted({video, state:!video.statue.started})">Toggle Started</v-btn>
        <v-btn class="debug" text x-small @click="controller.setFinished({video, state:!video.status.finished})">Toggle Finished</v-btn>
    </div>    

    <div class="video-details">
        <div class="video-title" v-html="video.title[language]"/>
        <div class="video-description" v-html="video.description[language]"/>
    </div>

    <v-card-actions style="text-align: center; border-top: 2px solid lightgrey;" class="pa-0">
        <v-container fluid>
            <v-row dense>
                <v-col>
                    <v-btn text x-small @click="controller.setLiked({video})">
                        <v-icon small :class="[{liked:video.status.liked}]">
                            mdi-thumb-up-outline
                        </v-icon>
                        <Str index="controller.video.button.like"/>
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn text x-small @click="controller.downloadSummary({video, state:true})">
                        <v-icon small>
                            mdi-file-download-outline
                        </v-icon>
                        <Str index="controller.video.button.summary"/>
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn text x-small>
                        {{video.duration[language]}} min
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import icon_play from '@/assets/icons/ic-play.svg'
import icon_watched from '@/assets/icons/ic-watched.svg'
import decoration_pink from '../assets/decoration_pink.svg'
import decoration_blue from '../assets/decoration_blue.svg'
import decoration_green from '../assets/decoration_green.svg'
import decoration_yellow from '../assets/decoration_yellow.svg'
export default {
    props: {
        debug: {
            type: Boolean,
            required: true
        },
        video: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        controller: {
            type: Object,
            required: true
        },
        videoIndex: {
            type: Number,
            required: true
        }
    },
    data(){
        return {
            colorWheel: ['pink','blue','green','yellow']
        }
    },
    methods: {
        gotoVideo(video){
            this.controller.gotoVideo(video)
        },
    },
    computed: {
        color(){
            let videoIndex = this.videoIndex
            let pointer = videoIndex % this.colorWheel.length

            return this.colorWheel[pointer]
        },
        decoration(){
            return {
                pink: decoration_pink,
                blue: decoration_blue,
                green: decoration_green,
                yellow: decoration_yellow,
            }
        },
        icons(){
            return {
                play: icon_play,
                finished: icon_watched
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.video-image{
    // border: 1px solid grey;
    height: 250px;
    display: flex;
    justify-content: center;
    background-size: contain;
}
.video-decoration{
    background-color: whitesmoke;
    background-size: cover;
    height: 38px;
    
}
.video-details{
    // border: 1px solid lightgrey;
    padding: 15px;
}
.video-title{
    min-height: 50px;
    font-weight: bold;
    font-size: 1em;
}
.video-description{
    height: 250px;
    overflow-y: auto;
}

.liked{
    color: $blue !important;
    caret-color: $blue !important;
}
</style>