var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"component-videos",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":"12","sm":"2"}}),_c('v-col',{class:[{'pl-8 pt-8':_vm.$vuetify.breakpoint.smAndUp}, {'pt-11':_vm.$vuetify.breakpoint.xsOnly}]},[_c('h1',[_c('Str',{attrs:{"index":"program.videos.title"}})],1),_c('p',{staticClass:"font-weight-bold"},[_c('Str',{attrs:{"index":"program.videos.description"}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"2"}})],1),(_vm.state.view=='selection' && _vm.moreThanThree)?_c('v-row',[_c('v-col',{staticClass:"pb-11"},[_c('MostLiked',{attrs:{"language":_vm.language,"VideoController":_vm.controller,"debug":_vm.debug}})],1)],1):_vm._e(),(_vm.$route.name=='Video')?_c('v-row',{staticClass:"crumbs"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col'):_vm._e(),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"12","sm":"10","align-self":"center"}},[_c('div',[_c('router-link',{attrs:{"to":{name:'Home'}}},[_c('Str',{attrs:{"index":"header.home"}})],1),_vm._l((_vm.$route.matched),function(route,index){return [(_vm.controller.selected)?_c('span',{key:`route-index-${index}`},[_vm._v(" > "),_c('router-link',{attrs:{"to":{name:route.name}}},[_vm._v(" "+_vm._s(_vm.$route.matched.length>1 && index==_vm.$route.matched.length-1 ? _vm.controller.selected.title[_vm.language] : _vm.$store.getters.LabelController.getLabel('program.videos.title')))])],1):_vm._e()]})],2)]),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col'):_vm._e()],1):_vm._e(),_c('v-row',{style:({
           'margin-top' : _vm.$vuetify.breakpoint.width>=1800 && _vm.state.view==='selection' ? '160px'
           : _vm.$vuetify.breakpoint.width>=1600 && _vm.state.view==='selection' ? '130px'
           : _vm.$vuetify.breakpoint.width>=1200 && _vm.state.view==='selection' ? '112px'
           : _vm.$vuetify.breakpoint.width>=1000 && _vm.state.view==='selection' ? '100px'
           : _vm.$vuetify.breakpoint.width>=960 && _vm.state.view==='selection' ? '90px' : 'initial'
           })},[_c('v-col',[(_vm.state.view=='selection')?[_c('VideoList',{attrs:{"language":_vm.language,"debug":_vm.debug,"controller":_vm.controller}})]:_vm._e(),(_vm.state.view=='player' && _vm.controller.selected)?_c('PlayVideo',{attrs:{"language":_vm.language,"debug":_vm.debug,"controller":_vm.controller}}):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }